import { useContext } from 'react'
import { CompanyContext } from 'contexts/CompanyContextProvider'

export function useSourceCompanyId() {
  const { sourceCompany, company } = useContext(CompanyContext)

  if (!sourceCompany?.id) {
    return ''
  }

  const isOwnCompany = company.id === sourceCompany?.id

  return !isOwnCompany ? sourceCompany?.id : ''
}
