import React, { useCallback, useContext, useState, useMemo } from 'react'
import { ISourceCompany } from 'types'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import { ListSelectDialog } from 'components/ListSelectDialog'

const OWN_COMPANY_PREFIX = '(You) '

export function RetailerSelectDialog() {
  const { sourceCompanies, sourceCompany, setSourceCompanyId, company } = useContext(CompanyContext)

  const [isOpen, setIsOpen] = useState(false)

  const listSelectItems = useMemo(
    () =>
      sourceCompanies.map(item => {
        const isOwnCompany = company.id === item.id

        return { ...item, ...(isOwnCompany ? { namePrefix: OWN_COMPANY_PREFIX } : {}) }
      }),
    [sourceCompanies, company.id]
  )

  const handleTriggerButton = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSelect = useCallback(
    (selectedCompany: ISourceCompany) => {
      handleClose()
      setSourceCompanyId(selectedCompany.id)
    },
    [handleClose, setSourceCompanyId]
  )

  if (sourceCompanies.length === 0) {
    return null
  }

  return (
    <ListSelectDialog
      items={listSelectItems}
      selected={listSelectItems.find(item => item.id === sourceCompany?.id)}
      open={isOpen}
      showAvatarOnTrigger
      onClose={handleClose}
      onOpen={handleTriggerButton}
      onSelect={handleSelect}
      title="Which retailer would you like to see data for?"
    />
  )
}
