import React from 'react'
import { IClassNameProps } from '@mondra/ui-components/@types'

export function CollaborateTrialIconSvg({ className }: IClassNameProps) {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask id="path-1-inside-1_747_46195" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9985 22.288C21.5845 20.213 23.9971 16.3353 23.9971 11.8943C23.9971 7.45316 21.5845 3.57555 17.9985 1.50049C14.4125 3.57555 12 7.45316 12 11.8943C12 16.3353 14.4125 20.213 17.9985 22.288Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9985 22.288C21.5845 20.213 23.9971 16.3353 23.9971 11.8943C23.9971 7.45316 21.5845 3.57555 17.9985 1.50049C14.4125 3.57555 12 7.45316 12 11.8943C12 16.3353 14.4125 20.213 17.9985 22.288Z"
        fill="#F97316"
      />
      <path
        d="M17.9985 22.288L17.4977 23.1536L17.9985 23.4434L18.4994 23.1536L17.9985 22.288ZM17.9985 1.50049L18.4994 0.634953L17.9985 0.345134L17.4977 0.634953L17.9985 1.50049ZM22.9971 11.8943C22.9971 15.964 20.7875 19.5188 17.4977 21.4225L18.4994 23.1536C22.3816 20.9071 24.9971 16.7067 24.9971 11.8943H22.9971ZM17.4977 2.36602C20.7875 4.26968 22.9971 7.82451 22.9971 11.8943H24.9971C24.9971 7.08182 22.3816 2.88143 18.4994 0.634953L17.4977 2.36602ZM13 11.8943C13 7.82451 15.2096 4.26968 18.4994 2.36602L17.4977 0.634953C13.6155 2.88143 11 7.08182 11 11.8943H13ZM18.4994 21.4225C15.2096 19.5188 13 15.964 13 11.8943H11C11 16.7067 13.6155 20.9071 17.4977 23.1536L18.4994 21.4225Z"
        fill="white"
        mask="url(#path-1-inside-1_747_46195)"
      />
      <path
        d="M13 12C13 5.92487 17.9249 1 24 1H35V12C35 18.0751 30.0751 23 24 23C17.9249 23 13 18.0751 13 12Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M23 12C23 18.0751 18.0751 23 12 23L1 23L1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}
