import React, { useContext } from 'react'
import { Dialog, Button, Tooltip, Icon } from '@mondra/ui-components'
import { IClassNameProps } from '@mondra/ui-components/@types'
import classNames from 'classnames'
import { CollaborateTrialBgSvg } from 'assets/CollaborateTrialBgSvg'
import { CollaborateTrialIconSvg } from 'assets/CollaborateTrialIconSvg'
import { UserContext } from 'contexts/UserContextProvider'
import { FreeTrialCounter } from 'components/trial-badge/FreeTrialCounter'
import {
  FEATURES_LIST,
  COMPLY_PLAN_FEATURES,
  COLLABORATE_PLAN_FEATURES,
  EMAIL_TEMPLATE,
} from './constants'
import { IFeatureMetaData } from './types'

export interface ICollaborateModalProps extends IClassNameProps {
  isOpen: boolean
  onClose: () => void
  trigger: React.ReactElement
}

interface IFeatureRow {
  metaData: IFeatureMetaData
}

function FeatureRow({ metaData }: IFeatureRow) {
  const { label, description, id, className } = metaData
  const complyHasFeature = COMPLY_PLAN_FEATURES.includes(id)
  const collaborateHasFeature = COLLABORATE_PLAN_FEATURES.includes(id)

  return (
    <div
      className={classNames(
        'flex h-14 items-center gap-4 border-t border-coolGray-100 hover:bg-coolGray-50',
        className
      )}
    >
      <div className="flex-1 cursor-pointer text-sm font-medium leading-4 underline decoration-teal-600 decoration-dotted decoration-[0.7px] underline-offset-[2.8px]">
        <Tooltip
          content={description}
          className="!max-w-[261px] rounded-sm px-4 py-3.5 text-xs"
          isTriggerPlain
        >
          <span>{label}</span>
        </Tooltip>
      </div>

      <div className="flex flex-1 justify-center">
        <Icon
          type={complyHasFeature ? 'checkmarkFilled' : 'locked'}
          className={complyHasFeature ? 'text-orange-500' : 'currentColor'}
          size={24}
        />
      </div>
      <div className="flex flex-1 justify-center">
        <Icon
          type={collaborateHasFeature ? 'checkmarkFilled' : 'locked'}
          className={collaborateHasFeature ? 'text-orange-500' : 'currentColor'}
          size={24}
        />
      </div>
    </div>
  )
}

export function CollaborateModal({ isOpen, onClose, className, trigger }: ICollaborateModalProps) {
  const { isComply, isCollaborate, isFreeTrial, expirationDate } = useContext(UserContext)
  const handleUnlockRequest = () => {
    const mailtoLink = `mailto:${EMAIL_TEMPLATE.mailto}?subject=${encodeURIComponent(
      EMAIL_TEMPLATE.subject
    )}&body=${encodeURIComponent(EMAIL_TEMPLATE.body)}`
    window.open(mailtoLink, '_blank')
  }

  return (
    <>
      {trigger}
      <Dialog
        open={isOpen}
        onClose={onClose}
        className={classNames('min-w-[764px] !max-w-[864px] rounded-lg p-0', className)}
      >
        <Dialog.Title
          showCloseButton
          onClose={onClose}
          buttonSize="sm"
          className="!mb-0 border-b border-gray-300 px-6 py-4 text-xl !font-medium !text-gray-900"
        >
          Choose the right plan for your team
        </Dialog.Title>
        <div className="flex flex-col gap-6 px-6 py-4">
          <div className="flex min-h-[116px] w-full gap-4">
            <div className="flex-1">
              <div className="flex h-full items-end text-sm font-semibold">Company features</div>
            </div>
            <div className="flex-1">
              <div className="flex h-full flex-col items-center justify-center gap-3 rounded-lg p-4 bg-secondary">
                <h6 className="font-semibold">Comply</h6>
                <span className="text-sm font-medium">
                  {isComply ? 'You current plan' : 'Our base plan'}
                </span>
              </div>
            </div>
            <div className="flex-1">
              <div className="relative h-full overflow-hidden rounded-lg bg-coolGray-700 p-4">
                <CollaborateTrialBgSvg className="absolute left-0 top-0" />
                <div className="relative z-px flex flex-col items-center justify-center gap-3">
                  <div className="flex items-center gap-2">
                    <CollaborateTrialIconSvg />
                    <h6 className="font-medium text-white">Collaborate</h6>
                  </div>
                  {isFreeTrial && (
                    <FreeTrialCounter
                      expirationDate={expirationDate!}
                      className="mb-4 @full-width:mb-0"
                      onClick={handleUnlockRequest}
                    />
                  )}
                  {isComply && (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleUnlockRequest}
                      className="!border-orange-500 !bg-orange-500 text-white hover:!border-orange-600 hover:!bg-orange-600 focus-visible:!ring-white"
                    >
                      Unlock these advantages
                    </Button>
                  )}
                  {isCollaborate && (
                    <div className="text-sm font-medium text-white">Your current plan</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {FEATURES_LIST.map(feature => (
              <FeatureRow metaData={feature} key={feature.id} />
            ))}
          </div>
        </div>
      </Dialog>
    </>
  )
}
