import React, { createContext, useMemo, useState } from 'react'
import { ISectionListItem } from 'types'

interface IAppSidebarProps {
  isExpanded: boolean
  collapseSidebar: () => void
  expandSidebar: () => void
  selected: ISectionListItem | null
  setSelected: React.Dispatch<React.SetStateAction<ISectionListItem | null>>
}

const defaultContextProps: IAppSidebarProps = {
  collapseSidebar: () => {},
  expandSidebar: () => {},
  isExpanded: true,
  selected: null,
  setSelected: () => null,
}

export const AppSidebarContext = createContext<IAppSidebarProps>(defaultContextProps)
interface IAppSidebarProviderProps {
  children: React.ReactNode
}

export default function AppSidebarContextProvider({ children }: IAppSidebarProviderProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [selected, setSelected] = useState<ISectionListItem | null>(null)

  const collapseSidebar = () => setIsExpanded(false)
  const expandSidebar = () => setIsExpanded(true)

  const value = useMemo(
    () => ({
      collapseSidebar,
      expandSidebar,
      isExpanded,
      selected,
      setSelected,
    }),
    [isExpanded, selected]
  )

  return <AppSidebarContext.Provider value={value}>{children}</AppSidebarContext.Provider>
}
