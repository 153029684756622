import React, { useContext, useMemo } from 'react'
import { Header, NavTabs } from '@mondra/ui-components'
import { Outlet, useLocation } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { ROUTE_URLS } from 'constants/routeUrls'
import {
  COMPANY_FEATURE_FLAGS,
  FeatureStateEnum,
  RELEASE_FEATURE_FLAGS,
} from 'constants/featureFlagKeys'
import { UserAvatar } from 'components/user-avatar/UserAvatar'
import { Search } from 'components/search/Search'
import { CopilotChat } from 'components/copilot-chat/Chat'
import { HelpIcon } from 'components/HelpIcon'
import { useCompanyId } from 'hooks/useCompanyId'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { useCompanyFeatures } from 'hooks/useCompanyFeatures'
import { resolveAppUrl } from 'utils/resolveUrl'
import { UserContext } from 'contexts/UserContextProvider'
import { useLockedFeatureModalContext } from 'contexts/LockedFeatureModalContextProvider'
import { isFeatureEnabled } from 'utils/isFeatureEnabled'
import { Padlock } from 'components/padlock/Padlock'
import { CompanySelectDialog } from './CompanySelectDialog'
import { RetailerSelectDialog } from './RetailerSelectDialog'

const RetailerFilterPaths = [
  ROUTE_URLS.HOME,
  ROUTE_URLS.ANALYSIS,
  ROUTE_URLS.SCENARIOS,
  ROUTE_URLS.ADD_DATA,
]

function ScenarioLabelComponent() {
  const { getFeatureLockState } = useCompanyFeatures()
  const padlockState = getFeatureLockState(COMPANY_FEATURE_FLAGS.SCENARIOS)

  return (
    <div className="flex items-center gap-2">
      <div>Scenarios</div>
      {padlockState && (
        <Padlock className="!h-5 !w-5" isLocked={padlockState === FeatureStateEnum.LOCKED} />
      )}
    </div>
  )
}

export default function Layout() {
  const companyId = useCompanyId()
  const { hasFeature, getFeatureLockState } = useCompanyFeatures()
  const { isLoading, isInactiveUser } = useContext(UserContext)
  const { open: openLockedFeatureModal } = useLockedFeatureModalContext()

  const { pathname } = useLocation()
  const showRetailerFilter = RetailerFilterPaths.some(p => pathname.indexOf(p) >= 0)

  const sourceCompanyId = useSourceCompanyId()
  const showHeaderNavigation = !isEmpty(companyId) && !isLoading && !isInactiveUser
  const isScenariosLocked =
    getFeatureLockState(COMPANY_FEATURE_FLAGS.SCENARIOS) === FeatureStateEnum.LOCKED
  const navLinks = useMemo(
    () =>
      showHeaderNavigation
        ? [
            {
              id: 'main-nav-home',
              label: 'Home',
              to: resolveAppUrl(ROUTE_URLS.HOME, companyId, sourceCompanyId),
            },
            {
              id: 'main-nav-analysis',
              label: 'Analysis',
              to: resolveAppUrl(ROUTE_URLS.ANALYSIS, companyId, sourceCompanyId),
            },
            {
              hidden: !hasFeature(COMPANY_FEATURE_FLAGS.SCENARIOS),
              id: 'main-nav-scenarios',
              label: ScenarioLabelComponent,
              to: resolveAppUrl(ROUTE_URLS.SCENARIOS, companyId, sourceCompanyId),
              ...(isScenariosLocked
                ? {
                    onClick: (e: React.MouseEvent) => {
                      e.preventDefault()
                      openLockedFeatureModal(COMPANY_FEATURE_FLAGS.SCENARIOS)
                    },
                  }
                : {}),
            },
            {
              hidden: !hasFeature(COMPANY_FEATURE_FLAGS.SUPPLIER_INVITATION),
              id: 'main-nav-suppliers',
              label: 'Suppliers',
              to: resolveAppUrl(ROUTE_URLS.ENGAGEMENTS, companyId, sourceCompanyId),
            },
            {
              hidden: !hasFeature(COMPANY_FEATURE_FLAGS.AG_DATA),
              id: 'main-nav-add-data',
              label: 'Add data',
              to: resolveAppUrl(ROUTE_URLS.ADD_DATA, companyId),
            },
          ]
        : [],
    [
      companyId,
      sourceCompanyId,
      hasFeature,
      isScenariosLocked,
      showHeaderNavigation,
      openLockedFeatureModal,
    ]
  )

  return (
    <div className="layout flex min-h-screen flex-col">
      <Header
        className="dark"
        gapClass=""
        left={
          <>
            <div className="border-r border-coolGray-700">
              <CompanySelectDialog />
            </div>
            {showRetailerFilter && (
              <div className="border-r border-coolGray-700">
                <RetailerSelectDialog />
              </div>
            )}
          </>
        }
        right={
          <div className="flex h-full items-center justify-between">
            {showHeaderNavigation && <Search />}
            <HelpIcon />
            <UserAvatar />
          </div>
        }
      >
        <div className="w-full">
          <div className="dark flex w-full justify-end space-x-8">
            <NavTabs options={navLinks} gapClass="gap-0" />
          </div>
        </div>
      </Header>
      <div className="flex h-full flex-grow flex-col">
        <Outlet />
        {isFeatureEnabled(RELEASE_FEATURE_FLAGS.MONDRA_COPILOT_ENABLED) &&
          hasFeature(COMPANY_FEATURE_FLAGS.MONDRA_COPILOT) && <CopilotChat />}
      </div>
    </div>
  )
}
