export enum PlanFeaturesEnum {
  IndustryStandard = 'IndustryStandard',
  EmissionInsights = 'EmissionInsights',
  AgriculturalData = 'AgriculturalData',
  DataQuality = 'DataQuality',
  Sharing = 'Sharing',
  CarbonFormulations = 'CarbonFormulations',
  CompliantReporting = 'CompliantReporting',
  IntegrateCarbon = 'IntegrateCarbon',
  CoalitionMembership = 'CoalitionMembership',
}

export interface IFeatureMetaData {
  className?: string
  description: JSX.Element | string
  label: string
  id: PlanFeaturesEnum
}
