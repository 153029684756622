import { FeatureStateEnum } from 'constants/featureFlagKeys'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import { useCallback, useContext } from 'react'

export function useCompanyFeatures() {
  const { company } = useContext(CompanyContext)

  const hasFeature = useCallback(
    (featureKey: string) => {
      return (
        !!company.featureStates &&
        Object.hasOwn(company, 'featureStates') &&
        Object.hasOwn(company.featureStates, featureKey) &&
        company.featureStates[featureKey] !== FeatureStateEnum.DISABLED
      )
    },
    [company]
  )

  const getFeatureState = useCallback(
    (featureKey: string) => {
      let featureState = FeatureStateEnum.DISABLED

      if (
        !!company.featureStates &&
        Object.hasOwn(company, 'featureStates') &&
        Object.hasOwn(company.featureStates, featureKey)
      ) {
        featureState = company.featureStates[featureKey]
      }

      return featureState
    },
    [company]
  )

  const getFeatureLockState = useCallback(
    (featureKey: string) => {
      const state = getFeatureState(featureKey)
      return [FeatureStateEnum.UNLOCKED, FeatureStateEnum.LOCKED].includes(state)
        ? state
        : undefined
    },
    [getFeatureState]
  )

  return {
    getFeatureLockState,
    getFeatureState,
    hasFeature,
  }
}
