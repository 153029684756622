import React from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'

interface IPadlockProps {
  className?: string
  isLocked?: boolean
}

export function Padlock({ className, isLocked = true }: IPadlockProps) {
  return (
    <div
      className={classNames(
        'flex h-6 w-6 items-center justify-center rounded-sm bg-orange-500',
        className
      )}
    >
      <Icon type={isLocked ? 'locked' : 'unlocked'} className="mx-1 text-white" />
    </div>
  )
}
