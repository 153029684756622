import React from 'react'
import { Dialog } from '@mondra/ui-components'
import { IClassNameProps } from '@mondra/ui-components/@types'
import classNames from 'classnames'
import { FeatureMetaDataMap } from './constants'
import { CollaborateTrialCard } from './CollaborateTrialCard'

export interface ILockedFeatureModalProps extends IClassNameProps {
  isOpen: boolean
  close: () => void
  feature: string
}

export function LockedFeatureModal({
  className,
  feature,
  isOpen,
  close,
}: ILockedFeatureModalProps) {
  const { title, description, imageUrl } = FeatureMetaDataMap[feature]

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      className={classNames('h-[640px] min-w-[960px] rounded-lg p-0', className)}
    >
      <Dialog.CloseButton onClose={close} buttonSize="sm" className="right-2.5 top-2.5" />
      <div className="flex h-full">
        <div className="flex w-[394px] min-w-[394px] flex-col p-6">
          <h6 className="pb-5 font-semibold text-primary">{title}</h6>
          <div className="text-sm leading-[18px] tracking-[-0.28px] text-secondary">
            {description}
          </div>
          <CollaborateTrialCard feature={feature} className="mt-auto !h-[228px] !min-w-full" />
        </div>
        <div className="flex w-full grow flex-col items-end justify-end bg-orange-100">
          <div
            className="h-[575px] w-[524px] rounded-tl-lg bg-cover bg-center"
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        </div>
      </div>
    </Dialog>
  )
}
