import React, { useContext } from 'react'
import AnalysisUrlContextProvider from 'contexts/AnalysisUrlContextProvider'
import ReportsContextProvider from 'contexts/ReportsContextProvider'
import SupplierFilterContextProvider from 'contexts/SupplierFilterContextProvider'
import { AppRoutes } from 'pages/AppRoutes'
import { AppFallback } from 'components/SuspenseFallback'
import { PendoTracker } from 'components/PendoTracker'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import CompanySyncContextProvider from 'contexts/CompanySyncContextProvider'
import SettingsContextProvider from 'contexts/SettingsContextProvider'
import ApolloClientProvider from 'contexts/ApolloClientContextProvider'
import AppSidebarProvider from 'contexts/AppSidebarContextProvider'
import { PageScrollContextProvider } from 'contexts/PageScrollContextProvider'
import { LockedFeatureModalContextProvider } from 'contexts/LockedFeatureModalContextProvider'
import { KbASAPContextProvider } from 'contexts/KbASAPContextProvider'

export function SecuredApp() {
  const { isLoading } = useContext(CompanyContext)

  if (isLoading) {
    return <AppFallback />
  }

  return (
    <CompanySyncContextProvider>
      <SettingsContextProvider>
        <SupplierFilterContextProvider>
          <PendoTracker>
            <AppSidebarProvider>
              <AnalysisUrlContextProvider>
                <ReportsContextProvider>
                  <PageScrollContextProvider>
                    <KbASAPContextProvider>
                      <ApolloClientProvider>
                        <LockedFeatureModalContextProvider>
                          <AppRoutes />
                        </LockedFeatureModalContextProvider>
                      </ApolloClientProvider>
                    </KbASAPContextProvider>
                  </PageScrollContextProvider>
                </ReportsContextProvider>
              </AnalysisUrlContextProvider>
            </AppSidebarProvider>
          </PendoTracker>
        </SupplierFilterContextProvider>
      </SettingsContextProvider>
    </CompanySyncContextProvider>
  )
}
