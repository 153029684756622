/**
 * The below feature keys will be present in
 * the company.features to enable the feature in the application.
 * Before adding the key & values into below, double check with CRM feature
 * keys.
 */

export const COMPANY_FEATURE_FLAGS = {
  AG_DATA: 'AgData',
  AG_DATA_ASSIGNMENT: 'AgDataAssignment',
  CONTRIBUTION_TREE: 'ContributionTree',
  EMISSIONS_CHART: 'EmissionOvertime',
  FLAG_REPORTS: 'FlagReports',
  INDUSTRY_AVERAGE: 'IndustryAverage',
  MONDRA_COPILOT: 'MondraCopilot',
  PRODUCTS_EXPORT: 'ProductsExport',
  SCENARIOS: 'Scenarios',
  SCOPE_3_REPORTS: 'Scope3Reports',
  START_DATE_SETTING: 'SVDataImportStartDate',
  SUPPLIER_INVITATION: 'SupplierInvitation',
}

export const RELEASE_FEATURE_FLAGS = {
  MONDRA_COPILOT_ENABLED: 'MONDRA_COPILOT_ENABLED',
}

export const REPORT_FEATURE_FLAGS = {
  FLAG_REPORTS: 'FlagReports',
  SCOPE_3_REPORTS: 'Scope3Reports',
}

export const enum FeatureStateEnum {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  LOCKED = 'Locked',
  UNLOCKED = 'Unlocked',
}
