import React from 'react'
import { COMPANY_FEATURE_FLAGS } from 'constants/featureFlagKeys'
import { SALES_MAIL } from 'constants/'
import { IFeatureMetaData, PlanFeaturesEnum } from './types'

const HELP_URL = {
  label: 'brcmondracoalition.com',
  link: 'https://brcmondracoalition.com/',
}

export const FEATURES_LIST: IFeatureMetaData[] = [
  {
    description:
      'Full Life Cycle Assessment Carbon footprints of your private label products, no data required! Integrated into your compliance workflow. Produced to the industry-approved standard your customer recognises.',
    id: PlanFeaturesEnum.IndustryStandard,
    label: 'Save money, with industry standard, dynamic LCAs',
  },
  {
    description:
      "Our suite of dashboards let you explore what's driving the emissions behind each of your products. The supply chain view and intensity scoring of product carbon enables you to spot issues and opportunities.",
    id: PlanFeaturesEnum.EmissionInsights,
    label: 'Drive reduction with emission insights for your products',
  },
  {
    description:
      'Create custom EF records from your primary farm data and apply them to specific products for accurate impact calculations. Signal your engagement with the Net Zero mission to your customer.',
    id: PlanFeaturesEnum.AgriculturalData,
    label: 'Lead the carbon conversation with integrated agricultural data',
  },
  {
    description:
      'Built-in industry-standard data quality metrics enable you to make informed decisions about your reduction strategies. Avoid wasted time by only actioning the most defensible data-led opportunities and use them as evidence of your engagement to your customer.',
    id: PlanFeaturesEnum.DataQuality,
    label: 'Make confident decisions with quantified data quality',
  },
  {
    description:
      'Strengthen your sustainability edge and add up to 5 users to your Mondra account.',
    id: PlanFeaturesEnum.Sharing,
    label: 'Share Mondra with your team',
  },
  {
    description:
      'Model NPD & EPD recipe variations of your products to optimise for carbon and other eco metrics. Build full proposals which deliver both commercial as well as carbon wins for you and your customer. Win market share.',
    id: PlanFeaturesEnum.CarbonFormulations,
    label: 'Engage your customers with carbon-considered formulations',
  },
  {
    description:
      'Meet both your legislative and voluntary requirements with quick report exports of your product & ingredient emissions. Ready to embed into all your Scope 3 reporting including SBTi FLAG, CPD, CSRD, WWF Basket and Courtauld.',
    id: PlanFeaturesEnum.CompliantReporting,
    label: 'Reduce costs and save time with simple, compliant reporting',
  },
  {
    description:
      'Increase your Mondra user account limit to cover commercial, sourcing, product and technical team members. Add Single Sign On (SSO). Access the Mondra API to bring product carbon data into your existing IT.',
    id: PlanFeaturesEnum.IntegrateCarbon,
    label: 'Be a Net Zero leader and integrate carbon into your workflow',
  },
  {
    className: 'text-white bg-[#21312E] hover:!bg-[#354F4A] rounded border-t-0 [&>div]:px-4',
    description: (
      <span>
        Become part of the industry wide carbon removal programme along with the UK&apos;s leading
        food retailers and suppliers.
        <br />
        <br />
        Find out more at:{' '}
        <a href={HELP_URL.link} className="underline" rel="noreferrer noopener" target="_blank">
          {HELP_URL.label}
        </a>
      </span>
    ),
    id: PlanFeaturesEnum.CoalitionMembership,
    label: 'PLUS: BRC Mondra Coalition Membership',
  },
]

export const COMPLY_PLAN_FEATURES = [
  PlanFeaturesEnum.IndustryStandard,
  PlanFeaturesEnum.EmissionInsights,
  PlanFeaturesEnum.AgriculturalData,
  PlanFeaturesEnum.DataQuality,
  PlanFeaturesEnum.Sharing,
]

export const COLLABORATE_PLAN_FEATURES = [
  PlanFeaturesEnum.IndustryStandard,
  PlanFeaturesEnum.EmissionInsights,
  PlanFeaturesEnum.AgriculturalData,
  PlanFeaturesEnum.DataQuality,
  PlanFeaturesEnum.Sharing,
  PlanFeaturesEnum.CarbonFormulations,
  PlanFeaturesEnum.CompliantReporting,
  PlanFeaturesEnum.IntegrateCarbon,
  PlanFeaturesEnum.CoalitionMembership,
]

export const COLLAB_PLAN_FEATURE = 'CollabPlan'

export const EMAIL_TEMPLATE = {
  body: "I'm interested in learning more about unlocking Mondra Collaborate and how it could benefit my company. Could we arrange a quick callback to discuss this?\n\n Looking forward to hearing from you!\n\nBest,\n[Your Name]\n[Your Contact Info]",
  mailto: SALES_MAIL,
  subject: 'Unlocking Collaboration',
}

const CARD_REPORTS_DESCRIPTION =
  'Unlock Mondra Collaborate, to access the reporting and more advanced tools, enhanced features, priority support.'

export const DEFAULT_CARD_DESCRIPTION =
  'Unlock more with Mondra Collaborate. Access advanced tools, enhanced features, priority support and much more.'

export const CARD_DESCRIPTION_BY_FEATURE = {
  [COLLAB_PLAN_FEATURE]:
    'Do more with Mondra Collaborate. Access advanced tools, enhanced features, priority support and much more.',
  [COMPANY_FEATURE_FLAGS.CONTRIBUTION_TREE]:
    'Unlock Mondra Collaborate, to access the contribution tree and more advanced tools, enhanced features, priority support.',
  [COMPANY_FEATURE_FLAGS.FLAG_REPORTS]: CARD_REPORTS_DESCRIPTION,
  [COMPANY_FEATURE_FLAGS.SCOPE_3_REPORTS]: CARD_REPORTS_DESCRIPTION,
  [COMPANY_FEATURE_FLAGS.SCENARIOS]:
    'Unlock Mondra Collaborate, to access the Scenarios and more advanced tools, enhanced features, priority support.',
  [COMPANY_FEATURE_FLAGS.PRODUCTS_EXPORT]:
    'Unlock Mondra Collaborate, to access the product table export, more advanced tools, enhanced features and priority support.',
}

const reportsMetaData = {
  description:
    'With Mondra, generating FLAG (Forest, Land, and Agriculture) and Scope 3 reports is as simple as a single click. Instantly access detailed, accurate insights into your supply chain’s environmental impact, including emissions from indirect sources. Streamline your reporting process and stay compliant with ease, while gaining the data you need to drive sustainable decision-making.',
  imageUrl: '/images/ReportsFeature.png',
  title: 'FLAG & Scope 3 reporting',
}

export const FeatureMetaDataMap = {
  [COMPANY_FEATURE_FLAGS.CONTRIBUTION_TREE]: {
    description:
      'The Contribution Tree is a powerful visual tool within Mondra’s platform that highlights the impact of every ingredient in a product’s supply chain. It offers a detailed breakdown of each ingredients carbon contribution, along with the activities driving these impacts. This allows you to quickly see which ingredients are driving impacts, empowering you to take targeted actions to reduce the overall environmental footprint.',
    imageUrl: '/images/ContributionTreeFeature.png',
    title: 'Contribution tree',
  },
  [COMPANY_FEATURE_FLAGS.FLAG_REPORTS]: reportsMetaData,
  [COMPANY_FEATURE_FLAGS.SCOPE_3_REPORTS]: reportsMetaData,
  [COMPANY_FEATURE_FLAGS.SCENARIOS]: {
    description:
      "Scenario modelling within Mondra empowers you to explore 'what if' scenarios, revealing the potential impact of changing ingredients in a product. This powerful tool helps you quantify the environmental impact of changes to your products, allowing you to identify significant opportunities. You can easily share these scenarios with your partners, enabling collaborative efforts to refine products and uncover opportunities for reducing environmental impacts.",
    imageUrl: '/images/ScenariosFeature.png',
    title: 'Scenario modelling',
  },
  [COMPANY_FEATURE_FLAGS.PRODUCTS_EXPORT]: {
    description:
      'Export all of your impact data as a spreadsheet with one click. Our product table export feature will instantly generate a csv file of your current view of the product table. Export from your full assortment or any category and apply filters to export only the data that you need to see.',
    imageUrl: '/images/ProductsExportFeature.png',
    title: 'Product table export',
  },
}
