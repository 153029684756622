import { useMemo } from 'react'
import { ISourceCompany } from 'types'
import { resolveApiUrl } from 'utils/resolveUrl'
import { API_URLS } from 'constants/apiUrls'
import { useSWRWithToken } from './useSWRWithToken'

export function useSourceCompanies(companyId?: string) {
  const { data, error, isLoading } = useSWRWithToken<ISourceCompany[]>(
    companyId ? resolveApiUrl(API_URLS.SOURCE_COMPANIES, { companyId }) : null,
    companyId
  )

  const sourceCompanies = useMemo(() => {
    // Do not show Own company if it is the only source company
    if (data?.length === 1 && data[0].id === companyId) {
      return []
    }

    return data || []
  }, [companyId, data])

  return {
    data: sourceCompanies,
    error,
    isLoading,
  }
}
