import React from 'react'
import { IClassNameProps } from '@mondra/ui-components/@types'

export function CollaborateTrialBgSvg({ className }: IClassNameProps) {
  return (
    <svg
      width="509"
      height="509"
      viewBox="0 0 491 509"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.6">
        <path
          d="M235.815 140.721C190.004 61.3751 217.19 -40.0841 296.536 -85.8945L440.205 -168.842L523.152 -25.173C568.962 54.1729 541.776 155.632 462.43 201.442C383.084 247.253 281.625 220.067 235.815 140.721Z"
          stroke="#292929"
          strokeWidth="7.44132"
        />
        <path
          d="M376.256 59.6337C422.066 138.98 394.88 240.439 315.534 286.249L171.866 369.196L88.9187 225.528C43.1083 146.182 70.2942 44.7227 149.64 -1.08772C228.986 -46.8981 330.445 -19.7122 376.256 59.6337Z"
          stroke="#292929"
          strokeWidth="7.44132"
        />
      </g>
    </svg>
  )
}
