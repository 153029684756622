import React from 'react'
import { Button } from '@mondra/ui-components'
import { IClassNameProps } from '@mondra/ui-components/@types'
import classNames from 'classnames'
import { useControls } from 'hooks/useControls'
import { CollaborateTrialBgSvg } from 'assets/CollaborateTrialBgSvg'
import { CollaborateTrialIconSvg } from 'assets/CollaborateTrialIconSvg'
import {
  DEFAULT_CARD_DESCRIPTION,
  CARD_DESCRIPTION_BY_FEATURE,
  COLLAB_PLAN_FEATURE,
} from './constants'
import { CollaborateModal } from './CollaborateModal'

export interface ICollaborateTrialCardProps extends IClassNameProps {
  feature?: string
}

export function CollaborateTrialCard({ className, feature }: ICollaborateTrialCardProps) {
  const { isOpened, open, close } = useControls()
  const description = feature ? CARD_DESCRIPTION_BY_FEATURE[feature] : DEFAULT_CARD_DESCRIPTION

  const isCollabPlan = feature === COLLAB_PLAN_FEATURE
  return (
    <div
      className={classNames(
        'dark relative flex h-full w-full min-w-[380px] flex-col justify-center overflow-hidden rounded-lg bg-coolGray-700 p-6',
        className
      )}
    >
      <CollaborateTrialBgSvg className="absolute left-0 top-0" />
      <div className="z-px">
        <CollaborateTrialIconSvg className="mb-3" />
        <div className="text-xl font-semibold text-white">
          {isCollabPlan ? 'You are on the Collaborate plan' : 'Do more with Collaborate'}
        </div>
        <div className="max-w-72 text-sm text-coolGray-300">{description}</div>
        <div className="mt-2 flex gap-4">
          <CollaborateModal
            isOpen={isOpened}
            onClose={close}
            trigger={
              <Button
                variant="primary"
                size="sm"
                className="!border-orange-500 !bg-orange-500 text-white hover:!border-orange-600 hover:!bg-orange-600 focus-visible:!ring-white"
                onClick={open}
              >
                {isCollabPlan ? 'View all the features' : 'Unlock all features'}
              </Button>
            }
          />
        </div>
      </div>
    </div>
  )
}
