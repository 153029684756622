/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useMemo } from 'react'
import { MenuPopover, Icon } from '@mondra/ui-components'
import { KbASAPContext, ASAPTabsEnum } from 'contexts/KbASAPContextProvider'
import { GET_SUPPORT } from 'constants/helpContent'

export function HelpIcon() {
  const { openASAP } = useContext(KbASAPContext)

  const options = useMemo(
    () => [
      {
        id: 'help-main',
        onClick: () => {
          openASAP()
        },
        value: 'Help',
      },
      {
        id: 'help-view-kb',
        onClick: () => {
          openASAP(ASAPTabsEnum.KB)
        },
        value: 'View Knowledge Base',
      },
      {
        id: 'help-submit-ticket',
        onClick: () => {
          window.open(GET_SUPPORT, '_blank')
        },
        value: 'Submit ticket',
      },
      {
        id: 'help-your-tickets',
        onClick: () => {
          openASAP(ASAPTabsEnum.TICKETS)
        },
        value: 'Your tickets',
      },
      // {
      //   id: 'help-contact-us',
      //   onClick: () => {
      //     openASAP()
      //   },
      //   value: 'Contact us',
      // },
    ],
    [openASAP]
  )

  return (
    <MenuPopover
      containerClass="dark mt-0.5"
      triggerButtonClass="h-14 w-14 !hover:bg-white/10"
      showBorder
      menuItems={options}
      trigger={
        // <Tooltip interactive placement="left" content="How can we help?">
        <Icon type="statusHelp" id="main-nav-help" className="text-current" />
      }
      // @ts-ignore
      id="main-nav-user"
    />
  )
}
