import React from 'react'
import classNames from 'classnames'
import { differenceInDays } from 'date-fns'
import { NOT_AVAILABLE } from 'constants/'
import pluralize from 'pluralize'

interface IPadlockProps {
  className?: string
  expirationDate: string
  onClick?: () => void
}

export function FreeTrialCounter({ className, expirationDate, onClick }: IPadlockProps) {
  const currentDate = new Date()
  const expDate = new Date(expirationDate)
  let counter = NOT_AVAILABLE
  if (expirationDate) {
    const differenceDays = differenceInDays(expDate, currentDate)
    counter = pluralize('Day', differenceDays > -1 ? differenceDays + 1 : differenceDays, true)
  }

  return (
    <div
      role="presentation"
      className={classNames(
        'group flex h-9 w-64 cursor-pointer rounded-full border border-coolGray-200 bg-white p-px hover:border-2 hover:border-orange-500 hover:p-0',
        className
      )}
      onClick={onClick}
    >
      <div className="flex items-center justify-center rounded-l-full rounded-r-sm bg-orange-500 px-3 text-sm text-white">
        {counter}
      </div>
      <div className="flex flex-1 items-center">
        <div className="flex items-center justify-center pl-2 text-xxs text-coolGray-500">
          Left on your Collaborate trial.
        </div>
        <div className="ml-2 h-7 w-px bg-coolGray-100" />
      </div>
      <div className="flex items-center justify-center px-3 text-sm font-semibold leading-[14px] text-teal-600 underline group-hover:text-orange-500">
        Unlock
      </div>
    </div>
  )
}
