import { useMemo } from 'react'
import { useCompanyId } from 'hooks/useCompanyId'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { REPORTS_HISTORY_PAGE_SIZE } from 'pages/reports/constants'
import { IReportHistoryData } from 'pages/reports/types'
import { useSWRWithToken } from './useSWRWithToken'

interface IReportHistoryRequestProps {
  reportType?: string
  pageSize?: number
}

interface IgetReportsHistoryUrl extends IReportHistoryRequestProps {
  companyId?: string
  sourceCompanyId?: string
}
export function getReportsHistoryUrl({
  companyId,
  pageSize = REPORTS_HISTORY_PAGE_SIZE,
  reportType,
  sourceCompanyId,
}: IgetReportsHistoryUrl) {
  return reportType && companyId
    ? resolveSwrUrl(
        API_URLS.REPORTS.GENERATE,
        {
          companyId,
          reportType,
        },
        { pageSize, ...(sourceCompanyId && { sourceCompanyId }) }
      )
    : null
}

export function useReportsHistory({ reportType, pageSize }: IReportHistoryRequestProps) {
  const companyId = useCompanyId()
  const sourceCompanyId = useSourceCompanyId()

  const url = useMemo(
    () =>
      getReportsHistoryUrl({
        companyId,
        pageSize,
        reportType,
        sourceCompanyId,
      }),
    [companyId, pageSize, reportType, sourceCompanyId]
  )

  const { data, error, isLoading, mutate } = useSWRWithToken<IReportHistoryData[]>(url, companyId)

  return {
    data,
    error,
    isLoading,
    refreshReportsList: mutate,
  }
}
