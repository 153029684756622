import React, { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { useToken } from 'hooks/useToken'
import { ROUTE_URLS } from 'constants/routeUrls'
import { UserClaimsEnum } from 'types'
import { MapSubscriptionTiers } from 'constants/subscriptionTiers'
import { CompanyContext } from './CompanyContextProvider'

interface IUserContext {
  expirationDate?: string
  isInactiveUser: boolean
  isLoading: boolean
  subscriptionTier?: string
  isComply: boolean
  isCollaborate: boolean
  isFreeTrial: boolean
}

const defaultUserContext = {
  expirationDate: '',
  isCollaborate: false,
  isComply: false,
  isFreeTrial: false,
  isInactiveUser: false,
  isLoading: true,
  subscriptionTier: '',
}

export const UserContext = createContext<IUserContext>(defaultUserContext)

interface IDecodedAccessToken {
  [UserClaimsEnum.SUBSCRIPTION_TIER]: string
}

interface IUserContextProviderProps {
  isAuthenticating: boolean
  children: React.ReactNode
}

export function UserContextProvider({ isAuthenticating, children }: IUserContextProviderProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [subscriptionTier, setSubscriptionTier] = useState<string>()
  const [expirationDate, setExpirationDate] = useState<string>()

  const { companies, isLoading: isCompaniesLoading, company } = useContext(CompanyContext)
  const { acquireToken } = useToken()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserClaims = async () => {
      try {
        const token = await acquireToken(company?.id)
        const decoded = jwtDecode<IDecodedAccessToken>(token)

        const tier = decoded[UserClaimsEnum.SUBSCRIPTION_TIER] || MapSubscriptionTiers.INACTIVE
        const date = decoded[UserClaimsEnum.EXPIRATION_DATE]

        setSubscriptionTier(tier)
        setExpirationDate(date)

        setIsLoading(false)

        if (tier === MapSubscriptionTiers.INACTIVE) {
          navigate(ROUTE_URLS.NEW_ACCOUNT)
        }
      } catch (error) {
        setIsLoading(false)
      }
    }

    if (
      !isAuthenticating &&
      !isCompaniesLoading &&
      ((companies.length > 0 && company?.id) || companies.length === 0)
    ) {
      setIsLoading(true)
      fetchUserClaims()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticating, acquireToken, company?.id, isCompaniesLoading, companies.length])

  const providerValue = useMemo(
    () => ({
      expirationDate,
      isCollaborate: subscriptionTier === MapSubscriptionTiers.COLLABORATE,
      isComply: subscriptionTier === MapSubscriptionTiers.COMPLY,
      isFreeTrial: subscriptionTier === MapSubscriptionTiers.FREE_TRIAL,
      isInactiveUser: subscriptionTier === MapSubscriptionTiers.INACTIVE,
      isLoading,
      subscriptionTier,
    }),
    [expirationDate, isLoading, subscriptionTier]
  )

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>
}
