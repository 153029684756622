import React, { createContext, useState, useContext, useMemo, useEffect, useCallback } from 'react'
import { useControls } from 'hooks/useControls'
import { LockedFeatureModal } from 'pages/home/collaborate-modal/LockedFeatureModal'

interface ILockedFeatureModalContext {
  close: () => void
  isOpen: boolean
  open: (feature: string) => void
}

const defaultLockedFeatureModalContext = {
  close: () => {},
  isOpen: false,
  open: () => {},
}

export const LockedFeatureModalContext = createContext<ILockedFeatureModalContext>(
  defaultLockedFeatureModalContext
)

interface ILockedFeatureModalContextProviderProps {
  children: React.ReactNode
}

export function LockedFeatureModalContextProvider({
  children,
}: ILockedFeatureModalContextProviderProps) {
  const [feature, setFeature] = useState<string>()
  const { isOpened, open, close } = useControls()

  const handleModalOpen = useCallback(
    (value: string) => {
      setFeature(value)
    },
    [setFeature]
  )

  const handleModalClose = useCallback(() => {
    close()

    // Wait for modal close animation to finish
    setTimeout(() => {
      setFeature(undefined)
    }, 200)
  }, [close, setFeature])

  useEffect(() => {
    if (feature) {
      open()
    }
  }, [feature, open, close])

  const providerValue = useMemo(
    () => ({
      close: handleModalClose,
      isOpen: isOpened,
      open: handleModalOpen,
    }),
    [isOpened, handleModalOpen, handleModalClose]
  )

  return (
    <LockedFeatureModalContext.Provider value={providerValue}>
      {children}
      {feature && (
        <LockedFeatureModal feature={feature} isOpen={isOpened} close={handleModalClose} />
      )}
    </LockedFeatureModalContext.Provider>
  )
}

export const useLockedFeatureModalContext = () => useContext(LockedFeatureModalContext)
