import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Icon, Tooltip } from '@mondra/ui-components'
import { IClassNameProps } from '@mondra/ui-components/@types'
import { useUpdateProductCategoryFavourite } from 'api/useUpdateProductCategoryFavourite'
import { useFavourites } from 'api/useFavourites'

interface IProductCategoryFavourite extends IClassNameProps {
  dimension: string
  dimensionId: string
  iconSize?: number
  isSet: boolean
  noTooltip?: boolean
  onChange?: (id: string, isFavourite: boolean) => void
}

export function ProductCategoryFavourite({
  className,
  dimension,
  dimensionId,
  iconSize = 18,
  isSet,
  noTooltip,
  onChange,
}: IProductCategoryFavourite) {
  const { post, remove } = useUpdateProductCategoryFavourite({ dimension, dimensionId })
  const { refreshFavourites } = useFavourites()
  const [isFavourite, setIsFavourite] = useState<boolean>(isSet)

  useEffect(() => {
    setIsFavourite(isSet)
  }, [isSet])

  const handleSetIsFavourite = useCallback(
    async (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation()
      setIsFavourite(prevState => !prevState)

      try {
        if (isFavourite) {
          await remove()
        } else {
          await post()
        }

        refreshFavourites()

        if (onChange) onChange(dimensionId, !isFavourite)
      } catch (error) {
        setIsFavourite(prevState => !prevState)
      }
    },
    [dimensionId, isFavourite, onChange, post, refreshFavourites, remove]
  )

  return (
    <Tooltip
      content={isFavourite ? 'Remove from favourites' : 'Add to favourites'}
      isTriggerPlain
      size="md"
      placement="right"
      className={classNames({ hidden: noTooltip })}
    >
      <Icon
        onClick={handleSetIsFavourite}
        size={iconSize}
        className={classNames(
          'group-hover:text-white dark:hover:text-primary-400 dark:group-hover:text-primary-400',
          isFavourite
            ? 'text-primary-500 dark:text-primary-400'
            : 'text-primary dark:text-secondary',
          className
        )}
        type={isFavourite ? 'bookmarkFilled' : 'bookmarkAdd'}
      />
    </Tooltip>
  )
}
