/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { createContext, useCallback, useLayoutEffect, useMemo } from 'react'
import { useToastNotification } from '@mondra/ui-components'

import { useZohoAsapUserToken } from 'api/useZohoAsapUserToken'
import { TOAST_POSITION } from 'constants/'
import { ZOHO_USER_TOKEN_ERROR } from 'constants/errorMessages'

type SuccessCallback = (token: string) => void
type FailureCallback = (error: any) => void
type InvokeOpenActionType = (action: string, o?: any) => void
type InvokeLoginType = (
  action: string,
  callback: (successCallback: SuccessCallback, failureCallback: FailureCallback) => void
) => void
type InvokeType = InvokeOpenActionType | InvokeLoginType

export enum ASAPTabsEnum {
  KB = 'KB',
  TICKETS = 'TICKETS',
  SALESIQ = 'salesiq',
}

declare global {
  interface Window {
    ZohoDeskAsap: {
      invoke: InvokeType
    }
    ZohoHCAsapSettings: {}
    ZohoDeskAsapReady: (a: any) => void
    ZohoDeskAsap__asyncalls: any
    ZohoDeskAsapReadyStatus: any
  }
}

window.ZohoHCAsapSettings = {
  hideLauncherIcon: true,
}

const defaultKbAsapContext = {
  openASAP: () => {},
}

interface IKbASAPContext {
  openASAP: (tabKey?: ASAPTabsEnum) => void
}

export const KbASAPContext = createContext<IKbASAPContext>(defaultKbAsapContext)

export function KbASAPContextProvider({ children }: { children: React.ReactNode }) {
  const { fetchZohoUserToken } = useZohoAsapUserToken()
  const { showError } = useToastNotification(TOAST_POSITION)

  useLayoutEffect(() => {
    const zohoScript = document.querySelector('[aria-label="zoho-portal"]')

    if (!zohoScript) {
      const d = document
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.defer = true
      s.ariaLabel = 'zoho-portal'
      s.id = 'zohodeskasapscript'
      s.src = process.env.REACT_APP_ZOHO_CHAT_URL as string
      d.getElementsByTagName('head')[0].appendChild(s)
      // eslint-disable-next-line func-names
      window.ZohoDeskAsapReady = function (a: any) {
        // eslint-disable-next-line no-multi-assign
        const e = (window.ZohoDeskAsap__asyncalls = window.ZohoDeskAsap__asyncalls || [])
        window.ZohoDeskAsapReadyStatus
          ? (a && e.push(s), e.forEach(et => et && et()), (window.ZohoDeskAsap__asyncalls = null))
          : a && e.push(a)
      }
    }
  }, [])

  const openASAP = useCallback(
    async (tabKey?: ASAPTabsEnum) => {
      const invokeAction = window.ZohoDeskAsap?.invoke as InvokeOpenActionType
      if (invokeAction) {
        invokeAction('logout')
      }

      try {
        const loginAction = window.ZohoDeskAsap?.invoke as InvokeLoginType
        // eslint-disable-next-line func-names
        loginAction('login', async function (successCallback, errorCallback) {
          const token = await fetchZohoUserToken()
          if (token) {
            successCallback(token)
          } else {
            errorCallback('Error while fetching token')
          }
        })
      } catch (error) {
        showError({
          label: ZOHO_USER_TOKEN_ERROR.AUTHENTICATION,
        })
      }

      if (invokeAction) {
        window.ZohoDeskAsapReady(function asapReady() {
          invokeAction('open', tabKey ? { tab: tabKey } : {})
        })
      }
    },
    [fetchZohoUserToken, showError]
  )

  const providerValue = useMemo(
    () => ({
      openASAP,
    }),
    [openASAP]
  )

  return <KbASAPContext.Provider value={providerValue}>{children}</KbASAPContext.Provider>
}
